import React, { Component, memo } from "react";
import { AgGridReact } from "ag-grid-react";
import EditBtnCellRenderer from "./editBtnCellRenderer";
import LabSearchMenu from "./labSearchMenu";
import {
    getUserRole,
    getUserDetails,
    getFullAddress,
    getUserAuthToken
} from "../../../../services/common/util";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import {
    auditEnums,
    serviceConstants
} from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import { fetchLabs } from "../../../../services/clinicPortalServices/labService";
import moment from "moment";

class ClinicLabGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnDefs: [
                {
                    headerName: "Actions",
                    minWidth: 150,
                    maxWidth: 150,
                    cellStyle: { textAlign: "center" },
                    cellRenderer: "editBtnCellRenderer"
                },
                {
                    headerName: "Name",
                    minWidth: 150,
                    resizable: true,
                    field: "name"
                },
                {
                    headerName: "Is Default?",
                    minWidth: 150,
                    resizable: true,
                    field: "isDefault",
                    valueGetter: (params) => {
                        return params.data.isDefault ? "Yes" : "No";
                    }
                },
                {
                    headerName: "Parent Company",
                    minWidth: 150,
                    resizable: true,
                    field: "parentCompany"
                },
                {
                    headerName: "Logo",
                    maxWidth: 100,
                    field: "logo",
                    resizable: true,
                    cellRenderer: memo((params) => {
                        if (params.data.logoImageFilePath) {
                            return (
                                <img
                                    src={
                                        serviceConstants.API_HOST_NAME +
                                        params.data.logoImageFilePath +
                                        "?" +
                                        new Date().getTime() +
                                        "&token=" +
                                        getUserAuthToken()
                                    }
                                    width="40px"
                                    alt="Logo"
                                />
                            );
                        }
                        return <div></div>;
                    })
                },
                {
                    headerName: "Address",
                    minWidth: 150,
                    resizable: true,
                    valueGetter: function addColumns(params) {
                        if (
                            params.data.address &&
                            params.data.address.address1
                        ) {
                            return (
                                getFullAddress(params.data.address) + ", USA"
                            );
                        } else {
                            return "";
                        }
                    }
                },
                {
                    headerName: "Phone Number",
                    minWidth: 150,
                    resizable: true,
                    field: "phoneNumber"
                },
                {
                    headerName: "Director Name",
                    minWidth: 150,
                    resizable: true,
                    field: "directorName"
                }
            ],
            components: {
                editBtnCellRenderer: EditBtnCellRenderer
            },
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true
            },
            rowData: [],
            context: { componentParent: this }
        };
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadGridData();
    };

    loadGridData = () => {
        fetchLabs().then((res) => {
            this.setState({ rowData: res.data });
        });
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    clearFilter = () => {
        this.gridApi.setFilterModel(null);
        this.gridApi.setQuickFilter(null);
        document.getElementById("reset-form").value = "";
    };

    onBtnExport = () => {
        this.gridApi.exportDataAsExcel({
            fileName:
                "Lab_Grid_" + moment().format("YYYY_MM_DD_HH_mm_ss") + ".xlsx"
        });
        const userData = JSON.parse(getUserDetails());
        const auditData = {
            identifier: auditEnums.IDENTIFIERS.ExportRecord,
            event_type: auditEnums.EVENTTYPES.LabGridExported,
            user_id: userData._id,
            user_name: userData.user_name + " (" + userData.role + ")",
            update_string: auditEnums.EVENTTYPES.LabGridExported
        };
        createAudit(auditData);
    };

    render() {
        let columnDefs = /salesperson|audit|salesadmin/.test(
            getUserRole().toLowerCase()
        )
            ? this.state.columnDefs.filter((x) => x.headerName !== "Actions")
            : this.state.columnDefs;
        return (
            <div className="clinic-contain">
                <LabSearchMenu
                    onFilterTextChange={this.onFilterTextChange}
                    clearFilter={this.clearFilter}
                    onBtnExport={this.onBtnExport}
                    context={this.state.context}
                />
                <div
                    style={{
                        width: "100%",
                        height: "calc(100vh - 250px)",
                        padding: "15px"
                    }}
                >
                    <ThemeContext.Consumer>
                        {({ themeName }) => (
                            <div
                                id="myGrid"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                                className={
                                    themeName === "Light"
                                        ? "ag-theme-alpine"
                                        : "ag-theme-alpine-dark"
                                }
                            >
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    masterDetail={true}
                                    onGridReady={this.onGridReady}
                                    rowData={this.state.rowData}
                                    pagination={true}
                                    paginationAutoPageSize={true}
                                    components={this.state.components}
                                    context={this.state.context}
                                />
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        );
    }
}

export default ClinicLabGrid;
